import pattern from "@/lib/const/pattern";

export const validationRules = {
  required: {
    required: true,
  },
  phoneNumber: {
    pattern: pattern.phone,
    message: "Số điện thoại không đúng định dạng",
  },
  maxLengthInput: { max: 160, message: "Không nhập quá 160 ký tự" },
  minLengthPassword: { min: 8, message: "Tối thiểu 8 ký tự" },
  gmail: {
    type: "email",
    message: "Email không đúng định dạng",
  },
};
