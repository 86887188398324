import React, { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { validationRules } from "@/shared";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "@/reducers/account";
import api from "@/lib/api";

const ChangePassModal = ({ open, setOpen, token }) => {
  const [formUpdatePass] = Form.useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const handleUpdatePass = async (data) => {
    const { newPassword, confirmPassword } = data;
    try {
      setLoading(true);
      const response = await api.post("/api/account/password-first", {
        newPassword,
        confirmPassword,
        token,
      });
      if (response.data.error) {
        formUpdatePass.setFields(response.data.error);
      } else if (response.data?.access_token) {
        dispatch(setLoggedIn(true));
        localStorage.setItem("access_token", response.data?.access_token);

        navigate("/histories");

        handleCancel();
      }
    } catch (e) {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Đổi mật khẩu"
      open={open}
      onCancel={handleCancel}
      width={335}
      footer={null}
      className="change-pass-modal">
      <div>Bạn cần đổi mật khẩu lần đầu để tiếp tục sử dụng</div>
      <div className="mb-[10px] mt-[15px]">
        <Form form={formUpdatePass} onFinish={handleUpdatePass} labelCol={{ span: 24 }}>
          <Form.Item
            className="mb-5"
            name="newPassword"
            rules={[validationRules.required, validationRules.minLengthPassword]}>
            <Input.Password placeholder="Nhập tối thiểu 8 ký tự" />
          </Form.Item>
          <Form.Item
            className="mb-5"
            name="confirmPassword"
            rules={[validationRules.required]}>
            <Input.Password placeholder="Nhập lại mật khẩu mới" />
          </Form.Item>
          <Button
            htmlType="submit"
            style={{ color: "white" }}
            type="dashed"
            className="bg-njgrey-800 border-none font-medium w-[100%]"
            size="large"
            loading={isLoading}>
            Cập nhật mật khẩu
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default ChangePassModal;
